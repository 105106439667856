// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://7a913ebaae654e49b5ab5d774742744f@o223518.ingest.sentry.io/5742410',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NEXT_PUBLIC_ENV || 'development',
  beforeSend(event, hint) {
    const error = hint.originalException;

    // skip "Linux" events as we don't support it
    const isLinux = event.request.headers['User-Agent'].includes('Linux');
    // _AutofillCallbackHandler" - https://developers.facebook.com/community/threads/320013549791141
    const isAutofillCallbackHandlerError = error?.message?.match(
      /_AutofillCallbackHandler/
    );

    if (isLinux || isAutofillCallbackHandlerError) {
      return null;
    }

    return event;
  },
  // explanation here: https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
});
