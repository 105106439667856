import Cookies from 'js-cookie';
// Types
import { Token } from 'context/AppContext';
// Helpers
import { isJsonString } from 'helpers/textEditor';
import { getCookiesDomain, getCookiesTokenEnvName } from './env';

export const TOKEN_NAME = 'token';
export const GUEST_TOKEN_NAME = 'guest-token';
export const COHOST_TOKEN_NAME = 'cohost-token';

const setItem = (key: string, value: string): void => {
  Cookies.set(key, value, {
    domain: getCookiesDomain(),
    sameSite: 'Lax',
    expires: 90,
  });
};

const getItem = (key: string): string | null => {
  return Cookies.get(key) ?? null;
};

const removeItem = (key: string): void => {
  Cookies.remove(key, {
    domain: getCookiesDomain(),
    sameSite: 'Lax',
    expires: 90,
  });
};

// Token
export const setTokenToCookies = (token: Token): void => {
  setItem(`${TOKEN_NAME}-${getCookiesTokenEnvName()}`, JSON.stringify(token));
};

export const getTokenFromCookies = (): Token | null => {
  const token = getItem(`${TOKEN_NAME}-${getCookiesTokenEnvName()}`);

  return token && isJsonString(token) ? JSON.parse(token) : null;
};

export const clearTokenFromCookies = (): void => {
  removeItem(`${TOKEN_NAME}-${getCookiesTokenEnvName()}`);
};

// Guest Token
export const setGuestTokenToCookies = (guestToken: Token): void => {
  setItem(
    `${GUEST_TOKEN_NAME}-${getCookiesTokenEnvName()}`,
    JSON.stringify(guestToken)
  );
};

export const getGuestTokenFromCookies = (): Token | null => {
  const guestToken = getItem(`${GUEST_TOKEN_NAME}-${getCookiesTokenEnvName()}`);

  return guestToken && isJsonString(guestToken) ? JSON.parse(guestToken) : null;
};

export const clearGuestTokenFromCookies = (): void => {
  removeItem(`${GUEST_TOKEN_NAME}-${getCookiesTokenEnvName()}`);
};

// Cohost token
export const setCohostTokenToCookies = (token: string): void => {
  setItem(`${COHOST_TOKEN_NAME}-${getCookiesTokenEnvName()}`, token);
};

export const getCohostTokenFromCookies = (): string | null => {
  return getItem(`${COHOST_TOKEN_NAME}-${getCookiesTokenEnvName()}`);
};

export const clearCohostTokenFromCookies = (): void => {
  removeItem(`${COHOST_TOKEN_NAME}-${getCookiesTokenEnvName()}`);
};

// Get one of existing token
export const getCohostOrRegularAccessTokenFromCookies = (): string | null => {
  return (
    getCohostTokenFromCookies() || getTokenFromCookies()?.accessToken || null
  );
};
