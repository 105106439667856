type SupportedParameters = {
  content_type?: 'product' | 'product_group';
  content_id?: string;
  content_category?: string;
  content_name?: string;
  currency?: string;
  value?: number;
  quantity?: number;
  price?: number;
  query?: string;
};

type Params = {
  contents?: SupportedParameters[];
} & SupportedParameters;

export const TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID;

const track = (event: string, params?: Params) => {
  window.ttq && window.ttq.track(event, params);
};

export const viewContent = (): void => {
  track('ViewContent');
};

export const completeRegistration = (params: Params): void => {
  track('CompleteRegistration', params);
};

export const addToCart = (params: Params): void => {
  track('AddToCart', params);
};

export const initiateCheckout = (params?: Params): void => {
  track('InitiateCheckout', params);
};

export const placeAnOrder = (params: Params): void => {
  track('PlaceAnOrder', params);
};
