import { useEffect } from 'react';
import { useRouter } from 'next/router';
// Lib
import * as ttq from 'lib/ttpixel';

const handleRouteChange = () => {
  ttq.viewContent();
};

// Requires "Tiktok Pixel" script in the _document.tsx file
export const useTiktokAnalytics = (): null => {
  const router = useRouter();

  useEffect(() => {
    ttq.viewContent();

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return null;
};
