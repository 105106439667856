import { useEffect } from 'react';
import { useRouter } from 'next/router';
// Lib
import * as fbq from 'lib/fpixel';

const handleRouteChange = () => {
  fbq.pageview();
};

// Requires "Facebook Pixel" script in the _document.tsx file
export const useFacebookPixel = (): null => {
  const router = useRouter();

  useEffect(() => {
    fbq.pageview();

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return null;
};
