export const isProduction = process.env.NEXT_PUBLIC_ENV === 'production';
export const isTestEnv = process.env.NODE_ENV === 'test';
export const isDevelopmentEnv = process.env.NODE_ENV === 'development';
export const isStagingEnv =
  process.env.NEXT_PUBLIC_ENV === 'staging' && !isDevelopmentEnv;
export const isStagingTwoEnv =
  process.env.NEXT_PUBLIC_ENV === 'staging2' && !isDevelopmentEnv;

export const getCookiesDomain = () => {
  if (isProduction || isStagingEnv) {
    return '.millions.co';
  }

  return '.localhost';
};

export const getCookiesTokenEnvName = () => {
  if (isProduction) {
    return 'prod';
  }

  if (isStagingEnv) {
    return 'stg';
  }

  return 'local';
};

export const getBackofficeUrl = () => {
  if (isProduction) {
    return 'https://admin.millions.co';
  }

  if (isStagingEnv) {
    return 'https://admin.stg1.millions.co';
  }

  return 'http://localhost:3030';
};
