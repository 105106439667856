import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const useScrollToTopOnLoad = (
  behavior: ScrollBehavior = 'smooth',
  left = 0,
  top = 0
): null => {
  const router = useRouter();
  const prevPath = useRef<string>('');

  useEffect(() => {
    const handleRouteChangeComplete = (path: string) => {
      const isNewPath = Boolean(prevPath.current && path !== prevPath.current);

      if (!prevPath.current.includes('?') && isNewPath) {
        window.scrollTo({ top, left, behavior });
      }

      prevPath.current = path;
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [behavior, left, top]);

  return null;
};
