import Script from 'next/script';
// Lib
import {
  GOOGLE_ANALYTICS_ID,
  GOOGLE_TAG_MANAGER_ID,
  GOOGLE_AW_CONVERSION_ID,
} from 'lib/google-analytics';
import { FB_PIXEL_ID } from 'lib/fpixel';
import { TIKTOK_PIXEL_ID } from 'lib/ttpixel';
// Helpers
import { isProduction, isStagingEnv } from 'helpers/env';
// Hooks
import { useDefferInitialization } from 'hooks';

const ExternalScripts = () => {
  const ready = useDefferInitialization();

  /**
   * Load scripts with a delay to improve page speed
   * performance & a metrics score
   */
  if (!ready) {
    return null;
  }

  return (
    <>
      {/* Facebook Pixel */}
      {FB_PIXEL_ID && (
        <>
          <Script
            id="connect-facebook-net"
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${FB_PIXEL_ID}');
              `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              alt=""
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
            />
          </noscript>
        </>
      )}

      {/* TikTok pixel */}
      {TIKTOK_PIXEL_ID && (
        <Script
          id="analytics-tiktok-com"
          dangerouslySetInnerHTML={{
            __html: `
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
              
                ttq.load('${TIKTOK_PIXEL_ID}');
                ttq.page();
              }(window, document, 'ttq');
            `,
          }}
        />
      )}

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {GOOGLE_ANALYTICS_ID && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
          />
          <Script
            id="googletagmanager-com-analytics-config"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${GOOGLE_ANALYTICS_ID}', {
                  page_path: window.location.pathname,
                });
                gtag('config', '${GOOGLE_AW_CONVERSION_ID}', {'allow_enhanced_conversions': true});
              `,
            }}
          />
        </>
      )}

      {/* Google Tag Manager */}
      {GOOGLE_TAG_MANAGER_ID && (
        <Script
          id="googletagmanager-com"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
            `,
          }}
        />
      )}

      {/* ShareASale "master" tag */}
      {isProduction && <Script src="https://www.dwin1.com/19038.js" />}

      {/* SegMetrics */}
      {(isProduction || isStagingEnv) && (
        <Script
          id="segmetrics-io"
          dangerouslySetInnerHTML={{
            __html: `var _segq = _segq || [];
              var _segs = _segs || {};
              (function () {var dc = document.createElement('script');
              dc.type = 'text/javascript';dc.async = true;
              dc.src = '//tag.segmetrics.io/aMpoAO.js';var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(dc,s);})();
            `,
          }}
        />
      )}

      {/* Google Tag Manager (noscript) */}
      {GOOGLE_TAG_MANAGER_ID && (
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe
                src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}"
                height="0"
                width="0"
                style="display:none;visibility:hidden"></iframe>
              `,
          }}
        />
      )}

      {/* OptiMonk */}
      {isProduction && (
        <Script
          id="optimonk-inline"
          dangerouslySetInnerHTML={{
            __html: `
              (function(e,a){
                var t,r=e.getElementsByTagName("head")[0],c=e.location.protocol;
                t=e.createElement("script");t.type="text/javascript";
                t.charset="utf-8";t.async=!0;t.defer=!0;
                t.src=c+"//front.optimonk.com/public/"+a+"/js/preload.js";r.appendChild(t);
              })(document,"136283");
            `,
          }}
        />
      )}

      {/* Hotjar Tracking Code */}
      {isProduction && (
        <Script
          id="hotjar-inline"
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3186624,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
      )}

      {/* HubSpot */}
      {isProduction && (
        <Script
          strategy="lazyOnload"
          id="hs-script-loader"
          src="//js.hs-scripts.com/19508085.js"
        />
      )}
    </>
  );
};

export default ExternalScripts;
