// Types
import { UserRole } from 'api/graphql-global-types';
import type { MerchShoppingCart } from 'context/AppContext';

export const REDIRECT = 'redirect';
export const STORE_SOCIAL_SIGNUP_ROLE = 'store-socail-signup';
export const MERCH_SHOPPING_CART = 'merch-shopping-cart';
export const RTMP_STREAM_ID = 'rtmp-stream-id';

const setItem = (key: string, value: string): void => {
  typeof window !== 'undefined' && localStorage.setItem(key, value);
};

const getItem = (key: string): string | null => {
  return typeof window !== 'undefined' ? localStorage.getItem(key) : null;
};

const removeItem = (key: string): void => {
  typeof window !== 'undefined' && localStorage.removeItem(key);
};

// Redirected from
export const setRedirectToLocalStorage = (path: string): void => {
  setItem(REDIRECT, path);
};

export const getRedirectFromLocalStorage = (): string | null => {
  return getItem(REDIRECT);
};

export const clearRedirectFromLocalStorage = (): void => {
  removeItem(REDIRECT);
};

// Store social sign-up role
export const setStoreSocialSignupToLocalStorage = (role: UserRole): void => {
  setItem(STORE_SOCIAL_SIGNUP_ROLE, role);
};

export const getStoreSocialSignupFromLocalStorage = (): string | null => {
  return getItem(STORE_SOCIAL_SIGNUP_ROLE);
};

export const clearStoreSocialSignupFromLocalStorage = (): void => {
  removeItem(STORE_SOCIAL_SIGNUP_ROLE);
};

// Shopping cart
export const addMerchShoppingCartToLocalStorage = (
  merchShoppingCart: MerchShoppingCart
): void => {
  const newShoppingCartItems = JSON.stringify(merchShoppingCart);

  setItem(MERCH_SHOPPING_CART, newShoppingCartItems);
};

export const getMerchShoppingCartFromLocalStorage = (): MerchShoppingCart => {
  const shoppingCart = getItem(MERCH_SHOPPING_CART);

  if (shoppingCart) {
    return JSON.parse(shoppingCart);
  }

  return [];
};

export const clearMerchShoppingCartFromLocalStorage = (): void => {
  removeItem(MERCH_SHOPPING_CART);
};

// RTMP
export const setRtmpStreamIdToLocalStorage = (id: string): void => {
  setItem(RTMP_STREAM_ID, id);
};

export const getRtmpStreamIdFromLocalStorage = (): string | null => {
  return getItem(RTMP_STREAM_ID);
};

export const clearRtmpStreamIdFromLocalStorage = (): void => {
  removeItem(RTMP_STREAM_ID);
};
