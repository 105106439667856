import { useEffect } from 'react';
import { useRouter } from 'next/router';
// Lib
import * as ga from 'lib/google-analytics';

const handleRouteChange = (url: string) => {
  ga.pageview(url);
};

// Requires "Global site tag (gtag.js) - Google Analytics" script in the _document.tsx file
export const useGoogleAnalytics = (): null => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return null;
};
