export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseToPlainText = (value: string | null | undefined): string => {
  if (value) {
    try {
      const isJson = isJsonString(value);
      let parsedValue = value;

      if (isJson) {
        parsedValue = JSON.parse(value);
      }

      return parsedValue.replace(/(<([^>]+)>)/gi, '');
    } catch (error) {
      return '';
    }
  }

  return '';
};

export const parseForInnerHTML = (value: string | null | undefined): string => {
  if (value) {
    const isJson = isJsonString(value);
    let parsedValue = value;

    if (isJson) {
      parsedValue = JSON.parse(value);
    }

    return parsedValue;
  }

  return '';
};
