export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GA_ID;
export const GOOGLE_AW_CONVERSION_ID =
  process.env.NEXT_PUBLIC_GA_AW_CONVERSION_ID;
export const GOOGLE_AW_CONVERSION_LABEL =
  process.env.NEXT_PUBLIC_GA_AW_CONVERSION_LABEL;
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GA_TAG_MANAGER_ID;

export const pageview = (url: string): void => {
  window.gtag &&
    window.gtag('config', GOOGLE_ANALYTICS_ID, {
      page_path: url,
    });
};

type Conversion = {
  send_to: string;
  value: number;
  currency?: string;
  transaction_id: string | number;
};

type EnhancedUserData = {
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
};

type GTagEvent = {
  action: string;
  category: string;
  label?: string;
  value?: number;
};

type EventParams = Pick<GTagEvent, 'label' | 'value'>;

type ProductItem = {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  coupon?: string;
  list_name?: number;
  list_position?: number;
  price?: number;
  quantity?: number;
  variant?: string;
};

type AddToCart = {
  currency?: string;
  items: ProductItem[];
  value?: number;
};

type BeginCheckout = {
  coupon?: string;
  currency?: string;
  items?: ProductItem[];
  value?: number;
};

type Purchase = {
  affiliation?: string;
  coupon?: string;
  currency?: string;
  shipping?: number;
  tax?: number;
  transaction_id?: string | number;
  value?: number;
  items?: ProductItem[];
};

type SignUp = {
  id?: string;
  name?: string;
  email?: string;
};

export const conversion = (params: Conversion): void => {
  window.gtag && window.gtag('event', 'conversion', params);
};

export const setEnhancedUserData = (userData: EnhancedUserData): void => {
  if (!userData.email && !userData.phoneNumber) {
    return;
  }
  window?.gtag?.('set', 'user_data', {
    ...(!!userData.email && { email: userData.email }),
    ...(!!userData.phoneNumber && { phone_number: userData.phoneNumber }),
  });
};

export const event = ({ action, category, label, value }: GTagEvent): void => {
  window.gtag &&
    window.gtag('event', action, {
      event_category: category,
      ...(label ? { event_label: label } : {}),
      ...(value ? { value: value } : {}),
    });
};

export const signUp = (category: string, params: SignUp): void => {
  window.gtag &&
    window.gtag('event', 'sign_up', {
      category,
      action: `sign_up_${category}`,
      label: `sign_up_${category}`,
      value: 1,
      ...params,
    });
};

export const addToCart = (params: AddToCart): void => {
  window.gtag && window.gtag('event', 'add_to_cart', params);
};

export const checkout = (params?: BeginCheckout): void => {
  window.gtag && window.gtag('event', 'begin_checkout', params);
};

export const confirmedPurchase = (params: Purchase): void => {
  window.gtag && window.gtag('event', 'purchase', params);
};

// Custom events
export const abandonedCheckout = (params?: EventParams): void => {
  event({ action: 'abandoned_checkout', category: 'ecommerce', ...params });
};
