export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = (): void => {
  window.fbq && window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}): void => {
  window.fbq && window.fbq('track', name, options);
};

export const customEvent = (name: string, options = {}): void => {
  window.fbq && window.fbq('trackCustom', name, options);
};

// Custom events
export const abandonedCheckout = (options = {}): void => {
  customEvent('abandoned_checkout', options);
};

export const addToCartAma = (options = {}): void => {
  customEvent('add_to_cart_ama', options);
};

export const addToCartWatchstream = (options = {}): void => {
  customEvent('add_to_cart_watchstream', options);
};
