import { useEffect, useState, useRef } from 'react';

export const useDefferInitialization = (seconds = 15) => {
  const [ready, setReady] = useState(false);
  const setTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const eventList = [
      'keydown',
      'mousemove',
      'wheel',
      'touchmove',
      'touchstart',
      'touchend',
    ] as const;

    const listener = () => {
      setReady(true);
    };

    if (!ready) {
      eventList.forEach((event) => {
        window.addEventListener(event, listener, { passive: true });
      });
    }

    return () => {
      eventList.forEach((event) => {
        window.removeEventListener(event, listener);
      });
    };
  }, [ready]);

  useEffect(() => {
    if (!ready) {
      const autoLoadTimeout = seconds * 1000;

      setTimeoutId.current = setTimeout(() => {
        setReady(true);
      }, autoLoadTimeout);
    }

    return () => {
      if (setTimeoutId.current) {
        clearTimeout(setTimeoutId.current);
        setTimeoutId.current = null;
      }
    };
  }, [ready, seconds]);

  return ready;
};
